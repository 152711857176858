import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClassRegisterState } from 'apps/early-stage-office/src/app/modules/class-register/store/class-register.state';
import { IGroup, ISimplifiedGroup } from 'apps/early-stage-office/src/app/core/models/group.interface';
import { WithDestroyComponent } from 'apps/early-stage-office/src/app/core/abstract/abstract-with-destroy-component';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { Router, RouterLink } from '@angular/router';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ProfileNavComponent } from '../header-profile/profile-nav/profile-nav.component';
import { TooltipDirective } from '@early-stage/components';
import { AsyncPipe, DatePipe, NgIf, NgStyle } from '@angular/common';
import { getPolishShortDayOfWeek } from '../../../../core/functions';
import { IconComponent } from '@early-stage/components';
import { ClassHeaderNavigationComponent } from './partials/class-header-navigation/class-header-navigation.component';
import { ClassHeaderScheduleItemComponent } from './partials/class-header-schedule-item/class-header-schedule-item.component';
import { ClassHeaderGroupInfoComponent } from './partials/class-header-group-info/class-header-group-info.component';
import { ClassHeaderControlsComponent } from './partials/class-header-controls/class-header-controls.component';
import { ClassHeaderGroupSelectComponent } from './partials/class-header-group-select/class-header-group-select.component';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';

@Component({
    selector: 'es-class-header-side-nav',
    templateUrl: './class-header-side-nav.component.html',
    styleUrls: ['./class-header-side-nav.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgStyle,
        IconComponent,
        RouterLink,
        TooltipDirective,
        ProfileNavComponent,
        AsyncPipe,
        DatePipe,
        IconComponent,
        ClassHeaderNavigationComponent,
        ClassHeaderScheduleItemComponent,
        ClassHeaderGroupInfoComponent,
        ClassHeaderControlsComponent,
        ClassHeaderGroupSelectComponent,
    ],
})
export class ClassHeaderSideNavComponent extends WithDestroyComponent implements OnInit, OnDestroy {
    public exitUrl: {
        url: string;
        queryParams?: { [key: string]: string };
    } = undefined;

    @Select(ClassRegisterState.group) public group$: Observable<IGroup>;
    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    public group: IGroup;
    @Select(ProfileState.groups) public groups$: Observable<ISimplifiedGroup[]>;
    public background: string = '#000';
    public color: string = '#fff';
    public stickyHeader: boolean = true;

    constructor(
        public router: Router,
        private store: Store
    ) {
        super();
    }

    public ngOnInit() {
        this.group$.pipe(takeUntil(this.destroy$)).subscribe(group => {
            this.group = group;

            if (this.group) {
                this.background = this.group.color?.background;
            }
        });
        disablePageScroll();

        this.exitUrl = this.store.selectSnapshot(LayoutState.returnUrl);
        if (this.exitUrl) {
            this.exitUrl.url = this.exitUrl.url.substring(1);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        enablePageScroll();
    }

    public offExpand() {
        setTimeout(() => {
            this.router.navigate([{ outlets: { menu: null } }]);
        });
    }

    public getDayOfWeek(day: number): string {
        return getPolishShortDayOfWeek(day);
    }
}
